<template>
    <div class="animated fadeIn">
        <!-- <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" /> -->
        <b-card >
            <template v-slot:header>
                <h5>History Barang</h5>
            </template>
            <b-col sm="4">
                <img :src="$logoPath" width="200px" />
            </b-col>
            <b-row>
                <b-col class="ml-4 mt-2">
                    <p>Barang :
                    </p>
        
                </b-col>
                <b-col>
                    
                </b-col>
            </b-row>
            <b-table bordered hover show-empty :busy="isBusy" :items="history" :fields="fields"
                :no-local-sorting="false" responsive class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data history barang.
                </template>
           
            </b-table>
            <div class="pagination-wrapper d-flex align-items-center justify-content-between">
                <b-input-group class="align-items-center tableShowItem">
                    <span class="ml-2">{{ totalViewText }}</span>
                </b-input-group>
                <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table" @input="onPageChange"></b-pagination>
            </div>
        </b-card>
        


    </div>
</template>

<script>
export default {
    name: 'master-tipe-barang',
    data() {
        return {
            initStatus: 0,
            isBusy: true,
            isSubmitting: false,
    
            input: {
                show: 10,
            },
            fields: [
                { key: "no", label: "No"},
                { key: "tanggal", label: "Tanggal" },
                { key: "penerima", label: "Penerima" },
                { key: "namaBarang", label: "Nama Barang" },
                { key: "detail", label: "Detail" },
                { key: "keterangan", label: "Keterangan" },
            ],
            filter: {
                search :null,
            },

            noBarang:null,

            history:[],

            currentPage: 1,
            perPage: null,
            rows: null,
            totalViewText: "",
            page: '',
            size: '',
            from: '',
            limit: '',
            loading: false,
            query: "",
        }
    },

    methods: {

        //----------------- Fungsi Inisialisasi -----------------

        init(){
            this.initStatus = 0
            this.noBarang = this.$route.params.id

            let getHistory = this.$axios.get("api/admin/laporan/history-barang?nomer_barang="+this.noBarang).then(response => {
                
                let datas = response.data.data
                let i = 1
                console.log(datas.data)
                for (var data of datas.data) {
                   this.history.push({
                        no : i,               
                        tanggal : data.tanggal,                     
                        penerima : data.name,                    
                        namaBarang : data.tipe,
                        detail : data.detail_barang,                    
                        keterangan : data.keterangan
                   })
                   i++
               }
               console.log(this.history)

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to
                this.loading = false

                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)
            })
            Promise.all([getHistory]).then(() => {
                this.initStatus = 1
                this.toggleBusy()
            })
            .catch(error => {
                console.log(error)
                this.initStatus = -1
            })
        },


        getHistory() {
           
            this.toggleBusy()
            if (this.filter.search != null) this.query = this.query + "&search=" + this.filter.search
            console.log(this.query);
            this.$axios.get("api/admin/laporan/history-barang?nomer_barang="+this.noBarang+this.query).then(response => {   
                this.history = []
               
                let datas = response.data.data
                let i = 1
                if(datas.currentPage != 1){
                    var data = Object.keys(datas.data).map((key) => datas.data[key]);
                }else{
                    var data = datas.data
                }
               
                for (var x of data) {
                   this.history.push({
                        no : i,               
                        tanggal : x.tanggal,                     
                        penerima : x.name,                    
                        namaBarang : x.tipe,
                        detail : x.detail_barang,                    
                        keterangan : x.keterangan
                   })
                   i++
               }
               this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to
                this.loading = false

                this.toggleBusy()
                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)

            })
                .catch(error => {
                    console.log(error)
                    console.log(error.response.data.reason)
                    this.$helper.parseError(this, error)
                    this.toggleBusy()
                    this.loading = false
                })
        },


        //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

        onLimitChange() {
            this.size = this.input.show
            this.perPage = this.input.show
            this.currentPage = 1
            this.page = 0
            this.query = this.$helper.makeQuery(0, this.size)
            this.getHistory()
        },

        onPageChange() {
            console.log(this.loading);
            if (this.loading == false) {
                console.log(this.currentPage);
                this.page = this.currentPage
                this.query = "&page=" + this.page

                this.getHistory()
            }
        },

        sortingChanged(ctx) {
            if (ctx.sortBy == "merk.name") {
                this.input.sortBy = "merk"
                this.input.sortDesc = ctx.sortDesc
                this.onFilter()
            } else if (ctx.sortBy != "") {
                this.input.sortBy = ctx.sortBy
                this.input.sortDesc = ctx.sortDesc
                this.onFilter()
            }
        },

        onReset() {
            this.filter.search = null
            // this.currentPage = 1
            // this.page = 0
            this.query = "&page=1"
            this.loading = true
            this.getHistory()
        },

        //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },

       
        updateTotalItem(total) {
            this.perPage = this.size
            this.rows = total
            if (this.limit > this.rows) limit = this.rows
            this.totalViewText = "Menampilkan " + this.from + " - " + this.limit + " dari " + this.rows + " entri"
        },


    
    },

    watch: {

    },

    created() {
        this.init()
    }
}
</script>
